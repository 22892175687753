import * as React from "react";

function RightArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="16"
      fill="none"
      viewBox="0 0 11 16"
    >
      <path
        fill="#fff"
        d="M10.331 7.993c0 .294-.117.56-.35.774l-7.583 6.666c-.467.4-1.196.4-1.662-.026a1.022 1.022 0 01.029-1.52l6.708-5.894L.765 2.1C.298 1.7.298 1.007.735.58 1.174.153 1.932.153 2.399.553L9.981 7.22c.233.213.35.48.35.773z"
      ></path>
    </svg>
  );
}

export default RightArrow;
