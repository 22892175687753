import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SectionTitle from "../ui/SectionTitle";
import SectionSubtitle from "../ui/SectionSubtitle";
import SectionParagraph from "../ui/SectionParagraph";
import Button from "../ui/Button";

const Cta = () => {
  return (
    <section className="bg-section">
      <div className="container mx-auto px-5 py-8 md:py-16">
        <div className="flex flex-row justify-between items-center flex-wrap lg:flex-nowrap gap-8 lg:gap-60">
          <div className="lg:w-4/6">
            <SectionSubtitle color="light">Współpraca</SectionSubtitle>
            <SectionTitle color="light">
              Chcesz pozbyć się swojego złomu?
            </SectionTitle>
            <SectionParagraph color="light" className="mb-8">
              Z nami to proste, wystarczy przywieżć ładunek, zważyć go i
              otrzymać pieniądze do ręki.
            </SectionParagraph>
            <Button url="#kontakt">NAPISZ DO NAS</Button>
          </div>
          <div className="lg:w-2/6">
            <div className="relative">
              <StaticImage
                className="cta-image"
                src="../../images/robot.jpg"
                alt="CTA"
              />
              <StaticImage
                className="cta-pattern"
                src="../../images/pattern-cta.png"
                alt="CTA pattern"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
