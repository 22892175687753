import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../ui/Button";

const Hero = () => {
  return (
    <section className="container mx-auto px-5 py-8 md:py-12">
      <div className="flex flex-row justify-between items-center flex-wrap lg:flex-nowrap gap-12">
        <div className="lg:w-1/3">
          <h1 className="text-sm font-semibold uppercase subtitle-decorator text-gray-500 subtitle-decorator--dark  mb-3">
            Skup elektroniki
          </h1>
          <h2 className="text-3xl md:text-5xl font-titles font-bold leading-none md:leading-snug mb-8">
            Zajmiemy się
            <br />
            <span className="text-secondary font-titles">Twoimi odpadami</span>
          </h2>
          <Button url="#o-nas">ZOBACZ</Button>
        </div>
        <div className="lg:w-2/3 flex flex-row justify-between flex-wrap lg:flex-nowrap space-x-8">
          <StaticImage src="../../images/banner2.jpg" alt="Banner" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
