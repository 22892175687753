import React from "react";
import SectionSubtitle from "../ui/SectionSubtitle";
import SectionParagraph from "../ui/SectionParagraph";

const AboutUs = () => {
  return (
    <section id="o-nas" className="container mx-auto px-5 py-8 md:py-16s">
      <SectionSubtitle>O nas</SectionSubtitle>
      <SectionParagraph className="mb-5">
        Firma Siler prowadzi działalność od 1995roku. Firma jest zlokalizowana w
        Nowym Stawie w województwie pomorskim. Zajmuje się gospodarką odpadami
        metali kolorowych, złomu stalowego, elektroniki, katalizatorów, węglików
        spiekanych, kabli oraz wyselekcjonowanych tworzyw sztucznych. Oferujemy
        kompleksową obsługę firm produkcyjno usługowych, jak i klientów
        detalicznych w obszarze tematyki odpadowej.
      </SectionParagraph>
      <SectionParagraph>Odbieramy odpady własnym transportem.</SectionParagraph>
    </section>
  );
};

export default AboutUs;
