import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import SectionTitle from "../ui/SectionTitle";
import SectionParagraph from "../ui/SectionParagraph";
import RightArrow from "../ui/icons/RightArrow";

const Services = () => {
  const query = useStaticQuery(graphql`
    query {
      elektronika: file(relativePath: { eq: "services/elektronika.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 800)
        }
      }
      metaleKolorowe: file(
        relativePath: { eq: "services/metale-kolorowe.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 800)
        }
      }
      stal: file(relativePath: { eq: "services/stal.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 800)
        }
      }
      katalizatory: file(relativePath: { eq: "services/katalizatory.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 800)
        }
      }
      kable: file(relativePath: { eq: "services/kable.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 800)
        }
      }
      weglik: file(relativePath: { eq: "services/weglik.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 800)
        }
      }
    }
  `);

  const servicesList = [
    {
      name: "Złom elektroniki",
      description:
        "Skupowane są takie metale jak: miedź, aluminium, stal, nikiel, ołów, cyna, brąz, czy też mosiądz.",
      image: query.elektronika.childImageSharp.gatsbyImageData,
      url: "/oferta/zlom-elektroniki",
    },
    {
      name: "Odpady metali kolorowych",
      description:
        "Skupowane są takie metale jak: miedź, aluminium, stal, nikiel, ołów, cyna, brąz, czy też mosiądz.",
      image: query.metaleKolorowe.childImageSharp.gatsbyImageData,
      url: "/oferta/odpady-metali-kolorowych",
    },
    {
      name: "Złom stalowy",
      description:
        "Skupowane są takie metale jak: miedź, aluminium, stal, nikiel, ołów, cyna, brąz, czy też mosiądz.",
      image: query.stal.childImageSharp.gatsbyImageData,
      url: "oferta/zlom-stalowy",
    },
    {
      name: "Odpady katalizatorów",
      description:
        "Skupowane są takie metale jak: miedź, aluminium, stal, nikiel, ołów, cyna, brąz, czy też mosiądz.",
      image: query.katalizatory.childImageSharp.gatsbyImageData,
      url: "oferta/odpady-katalizatorow",
    },
    {
      name: "Odpady kabli",
      description:
        "Skupowane są takie metale jak: miedź, aluminium, stal, nikiel, ołów, cyna, brąz, czy też mosiądz.",
      image: query.kable.childImageSharp.gatsbyImageData,
      url: "oferta/odpady-kabli",
    },
    {
      name: "Odpady węglika spiekanego",
      description:
        "Skupowane są takie metale jak: miedź, aluminium, stal, nikiel, ołów, cyna, brąz, czy też mosiądz.",
      image: query.weglik.childImageSharp.gatsbyImageData,
      url: "oferta/odpady-weglika-spiekanego",
    },
  ];

  return (
    <section className="container mx-auto px-5 py-8 md:py-16">
      <div className="flex flex-row justify-between flex-wrap lg:flex-nowrap gap-3 mb-6 lg:mb-0">
        <div className="md:w-1/3">
          <SectionTitle size="small">Usługi</SectionTitle>
        </div>
        <div className="md:w-2/3">
          <SectionParagraph>
            Skupujemy po atrakcyjnych cenach następujące materiały.
          </SectionParagraph>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {servicesList.map((service, i) => (
          <Link to={service.url} key={i} className="service-item mb-8">
            <div className="service-top-wrapper">
              <GatsbyImage image={service.image} alt={service.name} />
              <div className="w-14 h-14 bg-primary flex justify-center items-center absolute left-0 bottom-0">
                <RightArrow />
              </div>
              <div className="service-description p-10 flex justify-center text-center flex-col">
                <h3 className="text-2xl md:text-4xl font-semibold font-titles mb-6">
                  {service.name}
                </h3>
                <p>{service.description}</p>
                <div className="h-14 px-8 bg-primary flex justify-center items-center absolute left-0 bottom-0">
                  <span className="mr-6">Zobacz</span>
                  <RightArrow />
                </div>
                <StaticImage
                  className="pattern-services"
                  src="../../images/pattern-services.png"
                  alt="pattern"
                  layout="fixed"
                />
              </div>
            </div>
            <h2 className="text-xl font-bold mt-4">{service.name}</h2>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Services;
