import React from "react";
import { Link } from "gatsby";

const Button = ({ url, children, color = "primary", size = "normal" }) => {
  return (
    <Link
      to={url}
      className={`${
        color === "primary"
          ? "bg-primary border-primary hover:bg-white hover:text-primary hover:border-primary"
          : "bg-secondary border-secondary hover:border-opacity-70"
      }
        ${
          size === "normal"
            ? `text-lg py-3 px-10 md:px-24`
            : `text-base py-2 px-6`
        }
      text-white border-2 transition duration-500 inline-block`}
    >
      {children}
    </Link>
  );
};

export default Button;
